import React from "react";
import { Button, Card, Table } from "antd";
import { useUsers } from "./users.hook";

const Users = () => {
  const { users, loading } = useUsers();
  return (
    <Card>
      <div>
        <h1>Users</h1>
      </div>
      <Table
        loading={loading}
        scroll={{ x: 500 }}
        dataSource={users}
        columns={[
          {
            title: "Full Name",
            dataIndex: ["firm"],
            key: "name",
            render: (record: any) => (
              <Button type="link" href={`/firms/${record?.slug}`}>
                <span>
                  {record?.firstName || "-"} {record?.lastName || "-"}
                </span>
              </Button>
            ),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Phone",
            key: "phone",
            render: (record: any) => (
              <span>
                {record.firm?.phoneNumber?.replace(
                  /(\d{3})(\d{3})(\d{2})(\d{2})/,
                  "($1) $2 $3 $4",
                ) || "-"}
              </span>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Users;
