import { request } from "../request";

export const sendEmail = async (data: {
  email: string;
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(`email`, data);
  if (response.id) {
    return { success: true, message: "Email sent successfully" };
  } else {
    return { success: false, message: "Failed to send email" };
  }
};

export const getEmailSendingStatus = async () => {
  const response = await request.get(`email/status`);
  return response;
};

export const sendEmailToAllPotentialCustomers = async (data: {
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/potential-customers`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};

export const sendEmailToAllUsers = async (data: {
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/users`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};

export const sendEmailToAllNewsletterSubscribers = async (data: {
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/newsletter-subscribers`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};

export const sendEmailToSelectedPotentialCustomers = async (data: {
  emails: string[];
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/selected-potential-customers`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};

export const sendEmailToSelectedNewsletterSubscribers = async (data: {
  emails: string[];
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/selected-newsletter-subscribers`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};

export const sendEmailToProvidedEmails = async (data: {
  emails: string[];
  email_subject: string;
  email_body: string;
}) => {
  const response = await request.post(
    `email/send/provided-emails`,
    data,
    null,
    false,
    true,
  );
  if (response.success) {
    return { success: true, message: response.data.message };
  } else {
    return { success: false, message: "Failed to start the sending process" };
  }
};
