import {
  Input,
  Row,
  Col,
  Image,
  Upload,
  message,
  Select,
  Switch,
  Card,
  Space,
  Button,
} from "antd";
import React, { useCallback, useEffect } from "react";
import CustomEditor from "../CustomEditor";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  createNewCompany,
  updateFirmDetails,
  uploadProjectImage,
} from "../../services/firm/firm.service";
import { getCategories } from "../../services/category/category.service";

const FirmCreateModal = (props: {
  open: boolean;
  onCancel: () => void;
  triggerRefresh: () => void;
  company?: any;
  mode: "create" | "update";
}) => {
  const { mode, company } = props;
  const [categories, setCategories] = React.useState<any[]>([]);
  const [userFields, setUserFields] = React.useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [companyFields, setCompanyFields] = React.useState<any>({
    companyName: "",
    postCode: "",
    address: "",
    city: "",
    country: "",
    description: "",
    firstName: "",
    lastName: "",
    latitude: 0,
    longitude: 0,
    phoneNumber: "",
    practiceName: "",
    projectEmail: "",
    projectImages: [],
    website: "",
    categories: [],
    featured: false,
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (mode === "update") {
      setCompanyFields({
        ...company,
        categories: company.FirmCategories.map((item: any) => item.category.id),
      });
    }
  }, [company, mode]);

  const handleCreateCompany = async () => {
    setLoading(true);
    const result = await createNewCompany({
      ...userFields,
      ...companyFields,
    });
    console.log("result", result);
    setLoading(false);
    if (result?.firm) {
      props.triggerRefresh();
      message.success("Company created successfully");
      props.onCancel();
    } else {
      message.error("Failed to create company.");
    }
  };

  const handleUpdateCompany = async () => {
    setLoading(true);
    const result = await updateFirmDetails(props.company.id, companyFields);
    console.log("result", result);
    setLoading(false);
    if (result && result.firm) {
      props.triggerRefresh();
      message.success("Company updated successfully");
      props.onCancel();
    } else {
      message.error("Failed to update company.");
    }
  };

  const handleUploadProjectImage = async (file: any) => {
    setLoading(true);
    const result = await uploadProjectImage({
      file,
    });
    setLoading(false);
    if (result.url) {
      setCompanyFields((prev: any) => {
        return {
          ...prev,
          projectImages: [...prev.projectImages, result.url],
        };
      });
    } else {
      message.error("Failed to upload project image.");
    }
  };

  const handleUploadCompanyLogo = async (file: any) => {
    setLoading(true);
    const result = await uploadProjectImage({
      file,
    });
    setLoading(false);
    if (result.url) {
      setCompanyFields((prev: any) => {
        return {
          ...prev,
          logo: result.url,
        };
      });
    } else {
      message.error("Failed to upload company logo.");
    }
  };

  const handleGetCategories = useCallback(async () => {
    const result = await getCategories();
    console.log("result", result);
    if (result?.data) {
      setCategories(result.data);
    }
  }, []);

  useEffect(() => {
    handleGetCategories();
  }, [handleGetCategories]);

  const categoryOptions = categories.map((category) => ({
    label: category.title,
    value: category.id,
  }));

  return (
    <Card
      title={mode === "create" ? "Create Company" : "Edit Company"}
      extra={
        <Space>
          <Button onClick={props.onCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={
              mode === "create" ? handleCreateCompany : handleUpdateCompany
            }
            loading={loading}
          >
            {mode === "create" ? "Create" : "Update"}
          </Button>
        </Space>
      }
    >
      {
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <strong>Featured:</strong>
              <p>
                Turn on if you want this company to be listed in your featured
                list.
              </p>
              <Switch
                checked={companyFields?.featured}
                onChange={(checked) =>
                  setCompanyFields({
                    ...companyFields,
                    featured: checked,
                  })
                }
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            </div>
          </Col>
          {mode === "create" && (
            <>
              <Col span={24}>
                <h3 style={{ textAlign: "center" }}>
                  <strong>User Information</strong>
                </h3>
              </Col>
              <Col span={12}>
                <div>
                  <strong>Email:</strong>
                </div>
                <Input
                  value={userFields.email}
                  onChange={(e) =>
                    setUserFields({ ...userFields, email: e.target.value })
                  }
                />
              </Col>
              <Col span={12}>
                <div>
                  <strong>Password:</strong>
                </div>
                <Input
                  value={userFields.password}
                  onChange={(e) =>
                    setUserFields({ ...userFields, password: e.target.value })
                  }
                />
              </Col>
            </>
          )}
          <Col span={24}>
            <h3 style={{ textAlign: "center" }}>
              <strong>Company Information</strong>
            </h3>
          </Col>
          <Col span={12}>
            <div>
              <strong>Company Name:</strong>
            </div>
            <Input
              value={companyFields.companyName}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  companyName: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Post Code:</strong>
            </div>
            <Input
              value={companyFields.postCode}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  postCode: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Country:</strong>
            </div>
            <Input
              value={companyFields.country}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  country: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Address:</strong>
            </div>
            <Input
              value={companyFields.address}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  address: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>City:</strong>
            </div>
            <Input
              value={companyFields.city}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  city: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Categories:</strong>
            </div>
            <Select
              value={companyFields.categories}
              style={{
                width: "100%",
              }}
              mode="multiple"
              options={categoryOptions}
              onChange={(e) => {
                console.log("categories", companyFields.categories);
                setCompanyFields({
                  ...companyFields,
                  categories: e,
                });
              }}
            />
          </Col>
          <Col span={24}>
            <h3 style={{ textAlign: "center" }}>
              <strong>Contact Information</strong>
            </h3>
          </Col>
          <Col span={12}>
            <div>
              <strong>First Name:</strong>
            </div>
            <Input
              value={companyFields.firstName}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  firstName: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Last Name:</strong>
            </div>
            <Input
              value={companyFields.lastName}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  lastName: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Phone Number:</strong>
            </div>
            <Input
              value={companyFields.phoneNumber}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  phoneNumber: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Practice Name:</strong>
            </div>
            <Input
              value={companyFields.practiceName}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  practiceName: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Project Email:</strong>
            </div>
            <Input
              value={companyFields.projectEmail}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  projectEmail: e.target.value,
                })
              }
            />
          </Col>
          <Col span={12}>
            <div>
              <strong>Website:</strong>
            </div>
            <Input
              value={companyFields.website}
              onChange={(e) =>
                setCompanyFields({
                  ...companyFields,
                  website: e.target.value,
                })
              }
            />
          </Col>

          <Col span={24}>
            <div>
              <strong>Description:</strong>
            </div>
            <CustomEditor
              content={companyFields.description || ""}
              index={null}
              setContent={(newContent: any) => {
                setCompanyFields({ ...companyFields, description: newContent });
              }}
            />
          </Col>
          <Col span={24}>
            <div>
              <strong>Project Images:</strong>
            </div>
            <Row gutter={[16, 16]}>
              {companyFields.projectImages?.map((image: any) => (
                <Col span={6}>
                  <Image
                    src={image}
                    alt="project"
                    style={{
                      height: 200,
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 8,
                    }}
                  >
                    <DeleteOutlined
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        setCompanyFields({
                          ...companyFields,
                          projectImages: companyFields.projectImages.filter(
                            (img: any) => img !== image,
                          ),
                        });
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col span={24}>
                <Upload.Dragger
                  multiple
                  showUploadList={false}
                  accept="image/*"
                  customRequest={async (options) => {
                    await handleUploadProjectImage(options.file);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <PlusCircleOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag image file to this area to upload
                  </p>
                </Upload.Dragger>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div>
              <strong>Company Logo:</strong>
            </div>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                {companyFields.logo && (
                  <>
                    <Image
                      src={companyFields.logo}
                      alt="project"
                      style={{
                        height: 200,
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 8,
                      }}
                    >
                      <DeleteOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => {
                          setCompanyFields({
                            ...companyFields,
                            logo: "",
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col span={24}>
                <Upload.Dragger
                  multiple
                  showUploadList={false}
                  accept="image/*"
                  customRequest={async (options) => {
                    await handleUploadCompanyLogo(options.file);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <PlusCircleOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag image file to this area to upload
                  </p>
                </Upload.Dragger>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </Card>
  );
};

export default FirmCreateModal;
