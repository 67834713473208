import { request } from "../request";

export const getMailingList = async () => {
  const response = await request.get(`mailing-list`);
  return response;
};

export const searchMailingList = async (search: string) => {
  const response = await request.get(`mailing-list/search/${search}`);
  return response;
};
