import { useCallback, useEffect, useState } from "react";
import { getPayments } from "../../services/payment/payment.service";

export const usePayments = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<any>([]);

  const fetchPayments = useCallback(async () => {
    setLoading(true);
    const res = await getPayments();
    setLoading(false);
    if (res?.data) {
      setPayments(res.data);
    }
  }, []);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);
  return {
    loading,
    payments,
  };
};
