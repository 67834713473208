import { request } from "../request";

export const getAnalytics = async () => {
  const response = await request.get(`analytics`);
  return response;
};

export const getAnalyticsByUserId = async (userId: string) => {
  const response = await request.get(`analytics/${userId}`);
  return response;
};
