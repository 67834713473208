import { useCallback, useEffect, useState } from "react";
import {
  deleteContactMessage,
  getContactMessages,
} from "../../services/contact-us/contact-us.service";

export const useContact = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<any>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getContactMessages();
    setLoading(false);

    if (result) {
      setMessages(result);
    }
  }, []);

  const deleteMessage = async (id: string) => {
    const result = await deleteContactMessage(id);

    if (result) {
      fetchData();
    }

    return result;
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    messages,
    deleteMessage,
  };
};
