import React, { useCallback, useEffect, useState } from "react";
import styles from "./HomePageEdit.module.css";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Spin,
  Upload,
} from "antd";
import {
  getHomePageContent,
  updateHomePageContent,
} from "../../services/content/content.service";
import CustomEditor from "../CustomEditor";
import { uploadProjectImage } from "../../services/firm/firm.service";
import {
  getHomeHeroImage,
  updateHomeHeroImage,
} from "../../services/category/category.service";

const HomePageEdit = () => {
  const [form] = Form.useForm();
  const [titleBox, setTitleBox] = useState<any>(null);
  const [bannerBox, setBannerBox] = useState<any>(null);
  const [ctaBox, setCtaBox] = useState<any>(null);
  const [cookieNotice, setCookieNotice] = useState<any>(null);
  const [homeHeroImage, setHomeHeroImage] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);

  const handleGetHomeHeroImage = useCallback(async () => {
    const result = await getHomeHeroImage();
    if (result?.data) {
      setHomeHeroImage(result.data?.image);
    }
  }, []);

  const handleUpdateHomeHeroImage = async (file: any) => {
    setLoading(true);
    const result = await uploadProjectImage({
      file,
    });

    if (result?.url) {
      const res = await updateHomeHeroImage(result.url);

      if (res?.success) {
        await handleGetHomeHeroImage();
        message.success("Home hero image updated successfully");
      }
    } else {
      message.error("Failed to upload home hero image.");
    }
    setLoading(false);
  };

  const handleUpdateHomePageContent = async () => {
    try {
      setLoading(true);
      const response = await updateHomePageContent([
        titleBox,
        bannerBox,
        ctaBox,
        cookieNotice,
      ]);
      setLoading(false);
      if (response.success) {
        message.success("Home Page content updated successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetHomePageContent = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getHomePageContent();
      setLoading(false);

      if (response?.code) {
        console.log("Error");
      }
      const contents = response?.data;

      const titleBoxContent = contents.find(
        (content: any) => content.name === "title_box",
      );

      const bannerBoxContent = contents.find(
        (content: any) => content.name === "banner_box",
      );

      const ctaBoxContent = contents.find(
        (content: any) => content.name === "cta_box",
      );

      const cookieNoticeContent = contents.find(
        (content: any) => content.name === "cookie_notice",
      );

      setTitleBox(titleBoxContent);
      setBannerBox(bannerBoxContent);
      setCtaBox(ctaBoxContent);
      setCookieNotice(cookieNoticeContent);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    handleGetHomePageContent();
    handleGetHomeHeroImage();
  }, [handleGetHomePageContent, handleGetHomeHeroImage]);

  return (
    <div className={styles.container}>
      <Alert
        type="info"
        message="You can edit the content of the home page here. {{CurlyBrackets}} are used to color different parts of the text for the design."
      />
      {loading ? (
        <Spin spinning={loading} fullscreen size="large" />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <h3 style={{ textAlign: "center" }}>
                <strong>Home Hero Image</strong>
              </h3>

              <div>
                <Image
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                  src={homeHeroImage}
                  alt="Home Hero Image"
                  width={"100%"}
                  height={200}
                />
              </div>
              <div>
                <Upload.Dragger
                  multiple={false}
                  showUploadList={false}
                  accept="image/*"
                  progress={{ strokeWidth: 4, showInfo: true }}
                  customRequest={async (options) => {
                    await handleUpdateHomeHeroImage(options.file);
                  }}
                >
                  <p className="ant-upload-drag-icon">Home Hero Image</p>
                  <Image src={homeHeroImage} width={100} preview={false} />
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Upload.Dragger>
              </div>
            </Col>
            <Col span={24}>
              <Form form={form}>
                <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Card title="Title Box">
                      {titleBox?.title && (
                        <Form.Item
                          name="title_box_title"
                          initialValue={titleBox?.title}
                          rules={[
                            {
                              required: true,
                              message: "Please enter title box title",
                            },
                          ]}
                        >
                          <CustomEditor
                            content={titleBox?.title}
                            index={null}
                            setContent={(newContent: any) => {
                              setTitleBox({ ...titleBox, title: newContent });
                            }}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="title_box"
                        initialValue={titleBox?.content}
                        rules={[
                          {
                            required: true,
                            message: "Please enter title box content",
                          },
                        ]}
                      >
                        {titleBox?.content?.map(
                          (content: any, index: number) => (
                            <CustomEditor
                              content={content}
                              index={index}
                              setContent={(newContent: any) => {
                                const newTitleBox = titleBox?.content;
                                newTitleBox[index] = newContent;
                                setTitleBox({
                                  ...titleBox,
                                  content: newTitleBox,
                                });
                              }}
                            />
                          ),
                        )}
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="Banner Box">
                      {bannerBox?.title && (
                        <Form.Item
                          name="banner_box_title"
                          initialValue={bannerBox?.title}
                          rules={[
                            {
                              required: true,
                              message: "Please enter banner box title",
                            },
                          ]}
                        >
                          <CustomEditor
                            content={bannerBox?.title}
                            index={null}
                            setContent={(newContent: any) => {
                              setBannerBox({ ...bannerBox, title: newContent });
                            }}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        name="banner_box"
                        initialValue={bannerBox?.content}
                        rules={[
                          {
                            required: true,
                            message: "Please enter banner box content",
                          },
                        ]}
                      >
                        {bannerBox?.content?.map(
                          (content: any, index: number) => (
                            <CustomEditor
                              content={content}
                              index={index}
                              setContent={(newContent: any) => {
                                const newBannerBox = bannerBox?.content;
                                newBannerBox[index] = newContent;
                                setBannerBox({
                                  ...bannerBox,
                                  content: newBannerBox,
                                });
                              }}
                            />
                          ),
                        )}
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="CTA Box">
                      {ctaBox?.title && (
                        <Form.Item
                          name="cta_box_title"
                          initialValue={ctaBox?.title}
                          rules={[
                            {
                              required: true,
                              message: "Please enter CTA box title",
                            },
                          ]}
                        >
                          <CustomEditor
                            content={ctaBox?.title}
                            index={null}
                            setContent={(newContent: any) => {
                              setCtaBox({ ...ctaBox, title: newContent });
                            }}
                          />
                        </Form.Item>
                      )}

                      {ctaBox?.content?.map((content: any, index: number) => (
                        <CustomEditor
                          content={content}
                          index={index}
                          setContent={(newContent: any) => {
                            const newCtaBox = ctaBox?.content;
                            newCtaBox[index] = newContent;
                            setCtaBox({ ...ctaBox, content: newCtaBox });
                          }}
                        />
                      ))}
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="Cookie Notice">
                      {cookieNotice?.title && (
                        <Form.Item
                          name="cookie_notice_title"
                          initialValue={cookieNotice?.title}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Cookie Notice title",
                            },
                          ]}
                        >
                          <CustomEditor
                            content={cookieNotice?.title}
                            index={null}
                            setContent={(newContent: any) => {
                              setCookieNotice({
                                ...cookieNotice,
                                title: newContent,
                              });
                            }}
                          />
                        </Form.Item>
                      )}

                      {cookieNotice?.content?.map(
                        (content: any, index: number) => (
                          <Input.TextArea
                            key={index}
                            value={content}
                            onChange={(e) => {
                              const newCookieNotice = cookieNotice?.content;
                              newCookieNotice[index] = e.target.value;
                              setCookieNotice({
                                ...cookieNotice,
                                content: newCookieNotice,
                              });
                            }}
                            placeholder="Cookie Notice content"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        ),
                      )}
                    </Card>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  onClick={() => {
                    handleUpdateHomePageContent();
                  }}
                  style={{ marginTop: 20 }}
                >
                  Save and Publish
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default HomePageEdit;
