import { request } from "../request";

export const getFirms = async () => {
  const response = request.get("firm");
  return response;
};

export const getFirmDetails = async (slug: string) => {
  const response = request.get(`firm/${slug}`);
  return response;
};

export const updateFirmDetails = async (id: number, params: any) => {
  const response = request.put(`firm/${id}`, params);
  return response;
};

export const uploadProjectImage = async (params: { file: File }) => {
  const { file } = params;

  const formData = new FormData();
  formData.append("file", file, file.name);

  const response = await request.post(
    `firm/admin/project-image`,
    formData,
    {
      "Content-Type": "multipart/form-data",
    },
    true,
  );

  return response;
};

export const createNewCompany = async (params: any) => {
  const response = request.post(`firm/admin/create-company`, params);
  return response;
};
