import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Space, Table } from "antd";
import dayjs from "dayjs";
import { getBlogPosts } from "../../services/blog/blog.service";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { request } from "../../services/request";
import { useNavigate } from "react-router";

const BlogPosts = () => {
  const [posts, setPosts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const removePost = async (id: string) => {
    const result = await request.delete(`posts/${id}`);

    if (result) {
      fetchPosts();
    }
  };
  const togglePopular = async (id: string) => {
    const post = posts.find((post: any) => post.id === id);
    if (!post) {
      return;
    }

    if (post.popular) {
      const result = await request.post(`posts/popular`, {
        id,
        isPopular: false,
      });
      if (result) {
        fetchPosts();
      }
      return;
    }

    const result = await request.post(`posts/popular`, {
      id,
      isPopular: true,
    });

    if (result) {
      fetchPosts();
    }
  };
  const fetchPosts = async () => {
    setLoading(true);
    const res = await getBlogPosts();

    setLoading(false);

    if (res?.data) {
      setPosts(res.data);
    }
  };

  const toggleListed = async (id: string) => {
    const post = posts.find((post: any) => post.id === id);
    if (!post) {
      return;
    }

    if (post.listed) {
      const result = await request.put(`posts/listing-status`, {
        id,
        listed: false,
      });
      if (result) {
        fetchPosts();
      }
      return;
    }

    const result = await request.put(`posts/listing-status`, {
      id,
      listed: true,
    });

    if (result) {
      fetchPosts();
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);
  return (
    <Card>
      <div>
        <h1>Blog Posts</h1>
      </div>
      <Row justify="end" style={{ marginBottom: 8 }}>
        <Col span={4}>
          <Button
            href={"/blog/new"}
            icon={<PlusOutlined />}
            block
            type="primary"
          >
            Add New Post
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={posts}
        loading={loading}
        bordered
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "Author",
            dataIndex: "author",
            key: "author",
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: string) => (
              <span>{dayjs(createdAt).format("DD/MM/YYYY - HH:mm:ss")}</span>
            ),
          },
          {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (updatedAt: string) => (
              <span>{dayjs(updatedAt).format("DD/MM/YYYY - HH:mm:ss")}</span>
            ),
          },
          {
            title: "Listed",
            dataIndex: "listed",
            key: "listed",
            render: (listed: boolean, record: any) => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    toggleListed(record.id);
                  }}
                >
                  {listed ? (
                    <CheckOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <CloseOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </span>
              );
            },
          },
          {
            title: "Popular",
            dataIndex: "popular",
            key: "popular",
            render: (popular: boolean, record: any) => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    togglePopular(record.id);
                  }}
                >
                  {popular ? (
                    <CheckOutlined
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <CloseOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </span>
              );
            },
          },

          {
            title: "Action",
            dataIndex: "slug",
            key: "slug",
            render: (slug: string, record: any) =>
              record.status === "fulfilled" ? (
                "Tamamlandı"
              ) : (
                <Space>
                  <Button
                    type={"primary"}
                    size={"middle"}
                    onClick={async () => {
                      navigate(`/blog/edit/${slug}`);
                    }}
                    icon={<EditOutlined />}
                  />
                  <Button
                    type={"primary"}
                    size={"middle"}
                    onClick={async () => {
                      Modal.confirm({
                        title: "Delete post",
                        content: "Are you sure you want to delete this post?",
                        onOk: () => removePost(record.id),
                        okType: "danger",
                        okText: "Delete",
                      });
                    }}
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Space>
              ),
          },
        ]}
      />
      <Outlet />
    </Card>
  );
};

export default BlogPosts;
