import React from "react";
import { Card, Tabs } from "antd";
import HomePageEdit from "../../components/HomePageEdit";
import JoinPageEdit from "../../components/JoinPageEdit";
import SearchPageEdit from "../../components/SearchPageEdit";
import CategoryEdit from "../../components/CategoryEdit";

const ContentManagementPage = () => {
  return (
    <Card>
      <Tabs>
        <Tabs.TabPane key="1" tab="Home Page">
          <HomePageEdit />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Join Page">
          <JoinPageEdit />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Search Page">
          <SearchPageEdit />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="Category / Hero Image">
          <CategoryEdit />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default ContentManagementPage;
