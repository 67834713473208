import { request } from "../request";

export const getSubscriptions = async () => {
  const response = await request.get(`subscription`);
  return response;
};

export const getSubscriptionsByUserId = async (userId: string) => {
  const response = await request.get(`subscription/${userId}`);
  return response;
};
