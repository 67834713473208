import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import FirmProfile from "../../components/FirmProfile";
import { getFirmDetails } from "../../services/firm/firm.service";
import { getAnalyticsByUserId } from "../../services/analytics/analytics.service";
import { Line } from "@ant-design/charts";
import dayjs from "dayjs";
import { Card } from "antd";

const EditFirm = () => {
  const { slug } = useParams();
  const [company, setCompany] = React.useState<any>(null);
  const [analytics, setAnalytics] = React.useState<any>(null);

  const fetchCompany = useCallback(async () => {
    const result = await getFirmDetails(slug as string);
    if (result.firm) {
      setCompany(result.firm);
    }
  }, [slug]);

  const fetchCompanyAnalytics = useCallback(async () => {
    const result = await getAnalyticsByUserId(company.user.id as string);
    if (result) {
      setAnalytics(result);
    }
  }, [company]);

  useEffect(() => {
    if (slug) {
      // @ts-ignore
      fetchCompany();
    }
  }, [slug, fetchCompany]);

  useEffect(() => {
    if (company) {
      fetchCompanyAnalytics();
    }
  }, [company, fetchCompanyAnalytics]);

  const formatAnalyticsData = (data: any) => {
    return data?.map((item: any) => ({
      ...item,
      day: dayjs(item.createdAt).format("DD/MM/YYYY"),
    }));
  };

  const mergeSameDayAppearancesByCount = (data: any) => {
    const appearances = data?.reduce((acc: any, item: any) => {
      if (acc[item.day]) {
        acc[item.day] += 1;
      } else {
        acc[item.day] = 1;
      }
      return acc;
    }, {});

    if (!appearances) return [];
    return Object.keys(appearances).map((key) => ({
      day: key,
      appearances: appearances[key],
    }));
  };

  return (
    <div>
      <FirmProfile company={company} />
      <Card
        title="Firm Search Appearances by Day (Last 30 Days)"
        style={{ marginTop: 16 }}
      >
        {analytics?.firmSearchAppearances && (
          <Line
            data={mergeSameDayAppearancesByCount(
              formatAnalyticsData(analytics.firmSearchAppearances),
            )}
            xField="day"
            yField="appearances"
            seriesField="type"
            height={200}
          />
        )}
      </Card>
    </div>
  );
};

export default EditFirm;
