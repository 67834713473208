import React, { useCallback } from "react";
import { Card } from "antd";
import EmailSendComponent from "./EmailSendComponent";
import { getPotentialCustomers } from "../../services/potential-customers/potential-customers.service";
import { getAllUsers } from "../../services/users/users.service";
import { getMailingList } from "../../services/mailing-list/mailing-list.service";

const SendEmailPage = () => {
  const [list, setList] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleGetPotentialCustomers = async () => {
    const result = await getPotentialCustomers();
    if (result.data) {
      setList((prev) => ({
        ...prev,
        potentialCustomers: result.data,
      }));
    }
  };

  const handleGetAllUsers = async () => {
    const result = await getAllUsers();
    if (result) {
      setList((prev) => ({
        ...prev,
        allUsers: result,
      }));
    }
  };

  const handleGetAllNewsletterSubscribers = async () => {
    const result = await getMailingList();
    if (result) {
      setList((prev) => ({
        ...prev,
        newsletterSubscribers: result,
      }));
    }
  };

  const handleGetAllData = useCallback(async () => {
    setLoading(true);
    await handleGetPotentialCustomers();
    await handleGetAllUsers();
    await handleGetAllNewsletterSubscribers();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    handleGetAllData();
  }, [handleGetAllData]);

  return (
    <Card>
      <EmailSendComponent data={list} loading={loading} />
    </Card>
  );
};

export default SendEmailPage;
