import React from "react";
import { Button, Card, Col, Input, message, Modal, Row, Select } from "antd";
import styles from "../send-email.module.css";
import {
  sendEmailToAllNewsletterSubscribers,
  sendEmailToAllPotentialCustomers,
  sendEmailToAllUsers,
  sendEmailToProvidedEmails,
  sendEmailToSelectedNewsletterSubscribers,
  sendEmailToSelectedPotentialCustomers,
} from "../../../services/email/email.service";
import CustomEditor from "../../../components/CustomEditor";

interface Props {
  data?: {
    allUsers?: {
      email: string;
      name: string;
      id: number;
    }[];
    newsletterSubscribers?: {
      email: string;
      name: string;
      id: number;
    }[];
    potentialCustomers?: {
      email: string;
      name: string;
      id: number;
    }[];
  };
  loading?: boolean;
}

const EmailSend = (props: Props) => {
  const [selectedOption, setSelectedOption] =
    React.useState<string>("all_users");
  const [selectedEmails, setSelectedEmails] = React.useState<string[]>([]);
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [emailContent, setEmailContent] = React.useState<string>("");
  const [selectedContentType, setSelectedContentType] =
    React.useState<string>("html");
  const [innerLoading, setInnerLoading] = React.useState<boolean>(false);
  const { data, loading } = props;
  const options = [
    { label: "All Users", value: "all_users" },
    {
      label: "All Newsletter Subscribers",
      value: "all_newsletter_subscribers",
    },
    { label: "All Potential Customers", value: "all_potential_customers" },
    { label: "Provided Emails", value: "provided_emails" },
    {
      label: "Selected Newsletter Subscribers",
      value: "selected_newsletter_subscribers",
    },
    {
      label: "Selected Potential Customers",
      value: "selected_potential_customers",
    },
    {
      label: "Custom Email",
      value: "custom_email",
    },
  ];

  const hasOtherOptions =
    selectedOption === "selected_potential_customers" ||
    selectedOption === "selected_newsletter_subscribers";

  const otherOptionsRawData =
    selectedOption === "selected_potential_customers"
      ? data?.potentialCustomers
      : data?.newsletterSubscribers;

  const allUsersOptions = data?.allUsers?.map((item) => ({
    label: item.email,
    value: item.email,
  }));

  const otherOptions = otherOptionsRawData?.map((item) => ({
    label: item.email,
    value: item.email,
  }));

  const isCustomEmail = selectedOption === "custom_email";
  const isProvidedEmails = selectedOption === "provided_emails";

  const validateBeforeSubmit = () => {
    if (!emailSubject || !emailContent) {
      return false;
    }
    if (
      (selectedOption === "custom_email" ||
        selectedOption === "provided_emails" ||
        selectedOption === "selected_newsletter_subscribers" ||
        selectedOption === "selected_potential_customers") &&
      !selectedEmails.length
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateBeforeSubmit()) {
      return;
    }
    setInnerLoading(true);
    let result: {
      success: boolean;
      message: string;
    };
    switch (selectedOption) {
      case "all_users":
        result = await sendEmailToAllUsers({
          email_body: emailContent,
          email_subject: emailSubject,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "all_newsletter_subscribers":
        result = await sendEmailToAllNewsletterSubscribers({
          email_body: emailContent,
          email_subject: emailSubject,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "all_potential_customers":
        result = await sendEmailToAllPotentialCustomers({
          email_body: emailContent,
          email_subject: emailSubject,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "provided_emails":
        result = await sendEmailToProvidedEmails({
          email_body: emailContent,
          email_subject: emailSubject,
          emails: selectedEmails,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "selected_newsletter_subscribers":
        result = await sendEmailToSelectedNewsletterSubscribers({
          email_body: emailContent,
          email_subject: emailSubject,
          emails: selectedEmails,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "selected_potential_customers":
        result = await sendEmailToSelectedPotentialCustomers({
          email_body: emailContent,
          email_subject: emailSubject,
          emails: selectedEmails,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      case "custom_email":
        result = await sendEmailToProvidedEmails({
          email_body: emailContent,
          email_subject: emailSubject,
          emails: selectedEmails,
        });
        if (result?.success) {
          message.success("Email sending process started successfully");
          setEmailContent("");
          setSelectedEmails([]);
          setEmailSubject("");
        }
        break;
      default:
        break;
    }
    setInnerLoading(false);
  };

  return (
    <Card>
      <Row gutter={[16, 16]} justify="end">
        <Col span={24}>
          <Select
            defaultValue={selectedOption}
            options={options}
            className={styles.select}
            onChange={(value) => {
              setSelectedOption(value);
              setSelectedEmails([]);
            }}
          />
        </Col>
        {hasOtherOptions && (
          <Col span={24}>
            <Select
              mode="multiple"
              placeholder="Enter emails"
              className={styles.select}
              options={otherOptions}
              value={selectedEmails}
              onChange={(value) => {
                setSelectedEmails(value);
              }}
              showSearch
            />
          </Col>
        )}
        {isCustomEmail && (
          <Col span={24}>
            <Input
              className={styles.input}
              value={selectedEmails[0]}
              placeholder="Enter email"
              onChange={(e) => {
                setSelectedEmails([e.target.value]);
              }}
            />
          </Col>
        )}
        {isProvidedEmails && (
          <Col span={24}>
            <Select
              options={allUsersOptions}
              className={styles.select}
              placeholder={"Select emails"}
              value={selectedEmails}
              onChange={(value) => {
                setSelectedEmails(value);
              }}
              showSearch
              mode="multiple"
            />
          </Col>
        )}
        <Col span={24}>
          <Input
            placeholder="Enter subject"
            className={styles.input}
            value={emailSubject}
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }}
          />
        </Col>
        <Col span={24}>
          <Select
            defaultValue={selectedContentType}
            options={[
              { label: "HTML", value: "html" },
              { label: "Markdown", value: "markdown" },
            ]}
            className={styles.select}
            onChange={(value) => {
              setSelectedContentType(value);
              setEmailContent("");
            }}
          />
        </Col>
        <Col span={24}>
          {selectedContentType === "html" ? (
            <Input.TextArea
              rows={10}
              placeholder="Enter HTML content"
              className={styles.input}
              onChange={(e) => {
                setEmailContent(e.target.value);
              }}
            />
          ) : (
            <CustomEditor
              content={emailContent}
              index={0}
              setContent={setEmailContent}
            />
          )}
        </Col>
        <Col span={6}>
          <Button
            block
            className={styles.button}
            type={"primary"}
            onClick={() => {
              Modal.confirm({
                title: "Are you sure?",
                content:
                  "This action will send the email to all selected users",
                onOk: () => {
                  handleSubmit();
                },
              });
            }}
            loading={innerLoading || loading}
          >
            Send
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default EmailSend;
