import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  getAnalytics,
  getAnalyticsByUserId,
} from "../../services/analytics/analytics.service";

type Analytics = {
  totalSubscriptions: number;
  subscriptionsByDate: any[];
  totalRevenue: number;
  activeUsers: number;
  newUsers: number;
  mailingListEmailsOverTime: any[];
  abandonedBaskets: any[];
  pageVisitCountByFirms: any[];
  pageVisitDetails: any[];
  firmSearchAppearanceCounts: any[];
};
export const useDashboard = () => {
  const [data, setData] = useState<Analytics | null>(null);
  const [loading, setLoading] = useState(false);

  const mergeCountByDate = (data: any[]) => {
    const groupedData = data?.reduce((acc, item) => {
      const date = dayjs(item.createdAt).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = { createdAt: date, value: 0 };
      }
      acc[date].value += item._count?._all || 0;
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getAnalytics();
    setLoading(false);

    if (result) {
      setData({
        ...result,
        subscriptionsByDate: mergeCountByDate(result.subscriptionsByDate),
        mailingListEmailsOverTime: mergeCountByDate(
          result.mailingListEmailsOverTime,
        ),
        firmSearchAppearanceCounts: result.firmSearchAppearanceCounts.sort(
          (a: any, b: any) => {
            return a.firmName.localeCompare(b.firmName);
          },
        ),
        pageVisitCountByFirms: result.pageVisitCountByFirms.sort(
          (a: any, b: any) => {
            return a.firmName.localeCompare(b.firmName);
          },
        ),
      });
    }
  }, []);

  const fetchDataByUserId = async (userId: string) => {
    const result = await getAnalyticsByUserId(userId);

    if (result) {
      setData(result);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, fetchDataByUserId, loading };
};
