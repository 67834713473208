import { request } from "../request";

export const getAllNotifications = async () => {
  return request.get("notifications");
};

export const getUnreadNotifications = async () => {
  return request.get("notifications/unread");
};

export const setNotificationsRead = async (idList: number[]) => {
  if (idList.length === 0) return [];
  return request.put("notifications/read", { idList });
};
