import React, { useCallback, useEffect } from "react";
import styles from "./SearchPageEdit.module.css";
import { Image, message, Spin, Upload } from "antd";
import {
  updateSearchPageContent,
  uploadSearchPageMapUserIcon,
} from "../../services/content/content.service";
import { request } from "../../services/request";

const SearchPageEdit = () => {
  const [iconUrl, setIconUrl] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const handleUploadMapIcon = async (file: any) => {
    setLoading(true);
    const result = await uploadSearchPageMapUserIcon({ file });

    if (result?.url) {
      const res = await updateSearchPageContent({
        iconUrl: result.url,
        name: "map_user_marker_icon",
      });

      if (res.success) {
        await handleGetMapIcon();
        setLoading(false);
      }
    } else {
      message.error("Failed to upload map icon.");
    }
  };

  const handleGetMapIcon = useCallback(async () => {
    const result = await request.get("content/search-page");
    if (result?.data) {
      const jsonObject = result.data[0].content;
      setIconUrl(jsonObject);
    }
  }, []);

  useEffect(() => {
    handleGetMapIcon();
  }, [handleGetMapIcon]);

  return (
    <div className={styles.container}>
      <Spin spinning={loading} fullscreen size="large" />
      <Upload.Dragger
        multiple={false}
        showUploadList={false}
        accept="image/*"
        className={styles.dragger}
        progress={{ strokeWidth: 4, showInfo: true }}
        customRequest={async (options) => {
          await handleUploadMapIcon(options.file);
        }}
      >
        <p className="ant-upload-drag-icon">
          Search Page Map User Icon (You Are Here)
        </p>
        <Image src={iconUrl || ""} width={100} preview={false} />
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single file (only SVG)</p>
      </Upload.Dragger>
    </div>
  );
};

export default SearchPageEdit;
