import React from "react";
import { Card, Progress, Tag } from "antd";

type EmailStatusCardProps = {
  title: string;
  status?: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  loading: boolean;
};

const EmailStatusCard: React.FC<EmailStatusCardProps> = ({
  title,
  status,
  loading,
}) => {
  return (
    <Card
      loading={loading}
      title={title}
      extra={
        <>
          {status?.inProgress ? (
            <Tag color="orange">Sending...</Tag>
          ) : (
            <Tag color="green">Idle</Tag>
          )}
        </>
      }
    >
      {status && (
        <Progress
          percent={status.total > 0 ? (status.sent / status.total) * 100 : 0}
        />
      )}
      <p>Total: {status?.total}</p>
      <p>Sent: {status?.sent}</p>
    </Card>
  );
};

export default EmailStatusCard;
