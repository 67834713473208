import React, { FC, useCallback, useEffect } from "react";
import { Badge, Button, Drawer, Layout, List, Menu, MenuProps } from "antd";
import styles from "./layout.module.scss";
import {
  AlertOutlined,
  DollarCircleOutlined,
  FileSyncOutlined,
  HeartOutlined,
  MailOutlined,
  MessageOutlined,
  NotificationOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  ScheduleOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/icons/logo-white.png";
import { useNavigate } from "react-router";
import { STORAGE } from "../../storage";
import { combineClassnames } from "../../utils/combineClassnames";
import {
  getAllNotifications,
  getUnreadNotifications,
  setNotificationsRead,
} from "../../services/notification/notification.service";
import dayjs from "dayjs";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function createItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  createItem("Dashboard", "/dashboard", <PieChartOutlined />),
  createItem("Blog", "/blog", <HeartOutlined />),
  createItem("Users", "/users", <UserOutlined />),
  createItem("Firms", "/firms", <AlertOutlined />),
  createItem("Contact Us", "/contact-us", <MessageOutlined />),
  createItem("Payments", "/payments", <DollarCircleOutlined />),
  createItem("Subscriptions", "/subscriptions", <ScheduleOutlined />),
  createItem("Mailing List", "/mailing-list", <MailOutlined />),
  createItem("Content", "/content/management", <FileSyncOutlined />),
  createItem(
    "Potential Customers",
    "/potential-customers",
    <FileSyncOutlined />,
  ),
  createItem("Email Dashboard", "/email/dashboard", <SendOutlined />),
];
interface PageLayoutProps {
  children: React.ReactNode;
}

function NotificationDrawer(props: {
  getAllNotificationsAsync: () => void;
  open: boolean;
  onClose: () => void;
  renderItem: (item: any) => React.JSX.Element;
  notifications: {
    content: string;
    createdAt: string;
    read: boolean;
    title: string;
    type: string;
    userId: number;
  }[];
}) {
  return (
    <Drawer
      extra={
        <Button
          type="primary"
          onClick={() => {
            props.getAllNotificationsAsync();
          }}
        >
          All Notifications
        </Button>
      }
      open={props.open}
      onClose={props.onClose}
      title="Notifications"
    >
      <List dataSource={props.notifications} renderItem={props.renderItem} />
    </Drawer>
  );
}

function NotificationItem(props: {
  onClick: () => void;
  setNotificationRead: (id: number) => void;
  item: {
    id: number;
    content: string;
    createdAt: string;
    read: boolean;
    title: string;
    type: string;
    userId: number;
  };
  read: boolean;
}) {
  const { item, read } = props;
  return (
    <List.Item
      style={{
        backgroundColor: read ? "white" : "#f0f0f0",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
          }}
        >
          {item.type === "subscription_expiry" ? (
            <ScheduleOutlined style={{ color: "orange" }} />
          ) : (
            <NotificationOutlined style={{ color: "blue" }} />
          )}
          <div>
            <div
              style={{
                fontWeight: item.read ? "normal" : 500,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {item.title}
              <div style={{ fontSize: 12, color: "gray" }}>
                {dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
            <div
              style={{
                color: "gray",
                fontSize: 12,
                fontWeight: read ? "normal" : 500,
              }}
            >
              {item.content}
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const [selectedMenuItemKey, setSelectedMenuItemKey] =
    React.useState<React.Key>("1");
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    React.useState(false);
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [readNotifications, setReadNotifications] = React.useState<any[]>([]);
  const [collapsed, setCollapsed] = React.useState(false);

  const getUnreadNotificationsAsync = useCallback(async () => {
    const result = await getUnreadNotifications();
    if (result) {
      setNotifications(result);
    } else {
      console.error("Error while fetching notifications");
    }
  }, []);

  const getAllNotificationsAsync = useCallback(async () => {
    const result = await getAllNotifications();
    if (result) {
      setNotifications(result);
      const readOnes = result.filter((notification: any) => notification.read);

      setReadNotifications(
        readOnes.map((notification: any) => notification.id),
      );
    } else {
      console.error("Error while fetching notifications");
    }
  }, []);

  const navigate = useNavigate();

  const addNotificationRead = useCallback(
    (id: number) => {
      if (readNotifications.includes(id)) {
        return;
      } else {
        setReadNotifications([...readNotifications, id]);
      }
    },
    [readNotifications],
  );

  const handleSubmitNotificationsRead = useCallback(async () => {
    const result = await setNotificationsRead(readNotifications);
    if (result) {
      setNotifications(result);
      setReadNotifications([]);
    } else {
      console.error("Error while setting notifications read");
    }
  }, [readNotifications]);

  useEffect(() => {
    getUnreadNotificationsAsync();
  }, [getUnreadNotificationsAsync]);
  return (
    <Layout hasSider>
      <Sider
        className={styles.sider}
        collapsible
        collapsedWidth={70}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="md"
      >
        <div
          className={combineClassnames(
            styles.logoContainer,
            collapsed ? styles.collapsed : "",
          )}
        >
          <img src={Logo} className={styles.logo} alt="logo" />
        </div>
        <Menu
          theme="dark"
          className={styles.menu}
          selectedKeys={[selectedMenuItemKey as string]}
          mode="inline"
          items={items}
          onClick={({ key }) => {
            setSelectedMenuItemKey(key);
            navigate(key);
          }}
        />
      </Sider>
      <Layout rootClassName={styles.layout}>
        <NotificationDrawer
          open={isNotificationDrawerOpen}
          notifications={notifications}
          onClose={() => {
            handleSubmitNotificationsRead();
            setIsNotificationDrawerOpen(false);
          }}
          getAllNotificationsAsync={getAllNotificationsAsync}
          renderItem={(item: any) => (
            <NotificationItem
              onClick={() => {
                addNotificationRead(item.id);
              }}
              setNotificationRead={addNotificationRead}
              item={item}
              read={readNotifications.includes(item.id)}
            />
          )}
        />
        <Header style={{ padding: 0, background: "#1f1f1f" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              height: 60,
              paddingRight: 24,
              paddingLeft: 24,
            }}
          >
            <Badge
              count={
                notifications?.filter((notification) => !notification.read)
                  .length
              }
            >
              <Button
                type="primary"
                icon={<NotificationOutlined />}
                onClick={() => {
                  setIsNotificationDrawerOpen(true);
                }}
              />
            </Badge>

            <Button
              className={styles.logoutButton}
              type="primary"
              onClick={() => {
                STORAGE.removeRefreshToken();
                STORAGE.removeAccessToken();
                navigate("/login");
              }}
            >
              <PoweroffOutlined /> Logout
            </Button>
          </div>
        </Header>
        <Content
          style={{
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
