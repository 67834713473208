import React from "react";
import { Button, Card, Table } from "antd";
import { usePayments } from "./payments.hook";

const Payments = () => {
  const { payments, loading } = usePayments();

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "id",
      key: "paymentId",
    },
    {
      title: "Company Name",
      dataIndex: "firm",
      key: "companyName",
      render: (firm: { companyName: string; slug: string }) => {
        return (
          <Button type="link" href={`/firms/${firm.slug}`}>
            {firm.companyName}
          </Button>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => `£${amount}`,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "Stripe Payment Id",
      dataIndex: "stripePaymentId",
      key: "stripePaymentId",
    },
    {
      title: "Stripe Subscription Id",
      dataIndex: "stripeSubscriptionId",
      key: "stripeSubscriptionId",
    },
  ];
  return (
    <Card>
      <Table
        scroll={{
          x: true,
        }}
        dataSource={payments}
        columns={columns}
        loading={loading}
      />
    </Card>
  );
};

export default Payments;
