import React from "react";
import { Card, Table } from "antd";
import { useMailingList } from "./mailing-list.hook";

const MailingList = () => {
  const { list, loading } = useMailingList();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => (value ? <span>{value}</span> : "-"),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (value: string) => (value ? <span>{value}</span> : "-"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (value: string) => (value ? <span>{value}</span> : "-"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (value: string) => (value ? <span>{value}</span> : "-"),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => new Date(date).toLocaleString(),
    },
  ];
  return (
    <Card>
      <Table
        scroll={{
          x: true,
        }}
        dataSource={list}
        columns={columns}
        loading={loading}
      />
    </Card>
  );
};

export default MailingList;
