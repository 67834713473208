import { request } from "../request";

export const getCategories = async () => {
  const response = request.get("categories");
  return response;
};

export const updateCategory = async (id: number, image: string) => {
  const response = request.put(`categories/${id}`, { image });
  return response;
};

export const getHomeHeroImage = async () => {
  const response = request.get(`categories/home-hero-image`);
  return response;
};

export const updateHomeHeroImage = async (image: string) => {
  const response = request.put(`categories/home-hero-image`, { image });
  return response;
};
