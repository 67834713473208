import { useCallback, useEffect, useState } from "react";
import { getSubscriptions } from "../../services/subscription/subscription.service";

export const useSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<any>([]);

  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    const res = await getSubscriptions();
    setLoading(false);
    if (res) {
      setSubscriptions(res);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);
  return {
    loading,
    subscriptions,
  };
};
