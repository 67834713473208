import React from "react";
import { Card, Col, Row, Spin, Statistic, Tooltip } from "antd";
import { Line, Column, Pie } from "@ant-design/charts";
import { useDashboard } from "./dashboard.hook";

const Dashboard = () => {
  const { data, loading } = useDashboard();

  const pieConfig = {
    data: data?.firmSearchAppearanceCounts,
    angleField: "count",
    colorField: "firmName",
    label: {
      text: "firmName",
      position: "outside",
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };

  return (
    <div
      style={{
        minHeight: "400px",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <Spin spinning={loading} size="large" />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card>
                <Statistic title="Active Users" value={data?.activeUsers} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="New Users" value={data?.newUsers} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total Revenue"
                  value={data?.totalRevenue}
                  prefix={"£"}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total Subscriptions"
                  value={data?.totalSubscriptions}
                />
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
            {data?.firmSearchAppearanceCounts && (
              <Col span={24}>
                <Card>
                  <h4>
                    Firm Search Appearance Counts{" "}
                    <Tooltip
                      title={
                        "This chart shows the number of times each firm has appeared in the 1st page in search results."
                      }
                    >
                      <span
                        style={{
                          color: "grey",
                        }}
                      >
                        (?)
                      </span>
                    </Tooltip>
                  </h4>

                  <Pie
                    {...pieConfig}
                    style={{
                      height: "300px",
                    }}
                  />
                </Card>
              </Col>
            )}
            {data?.subscriptionsByDate && (
              <Col span={12}>
                <Card>
                  <h4>Subscriptions by Date</h4>
                  <Line
                    data={data?.subscriptionsByDate}
                    xField="createdAt"
                    yField="value"
                    height={300}
                  />
                </Card>
              </Col>
            )}
            {data?.mailingListEmailsOverTime && (
              <Col span={12}>
                <Card>
                  <h4>Mailing List Emails Over Time</h4>
                  <Line
                    data={data?.mailingListEmailsOverTime}
                    xField="createdAt"
                    yField="value"
                    height={300}
                  />
                </Card>
              </Col>
            )}
            {data?.pageVisitCountByFirms && (
              <Col span={24}>
                <Card>
                  <h4>Page Visit Count By Firms</h4>
                  <Column
                    data={data?.pageVisitCountByFirms}
                    xField="firmName"
                    yField="count"
                    height={300}
                    colorField={"firmName"}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default Dashboard;
