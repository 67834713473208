import { request } from "../request";

export const getPotentialCustomers = async () => {
  const response = request.get("potential-customers");
  return response;
};

export const uploadPotentialCustomersCSV = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = request.post(
    "potential-customers/upload-potential-customers",
    formData,
    {
      "Content-Type": "multipart/form-data",
    },
    true,
  );
  return response;
};

export const deletePotentialCustomer = async (id: number) => {
  const response = request.delete(`potential-customers/${id}`);
  return response;
};
