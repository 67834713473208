import { useCallback, useEffect, useState } from "react";
import { getMailingList } from "../../services/mailing-list/mailing-list.service";

export const useMailingList = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);

  const fetchList = useCallback(async () => {
    setLoading(true);
    const res = await getMailingList();
    setLoading(false);
    if (res) {
      setList(res);
    }
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);
  return {
    loading,
    list,
  };
};
