import { request } from "../request";

export const getPayments = async () => {
  const response = await request.get(`payment`);
  return response;
};

export const getPaymentsByUserId = async (userId: string) => {
  const response = await request.get(`payment/${userId}`);
  return response;
};

export const getSessionStatusBySessionId = async (sessionId: string) => {
  const response = await request.get(`payment/session/${sessionId}`);
  return response;
};
