import { request } from "../request";

type Content = {
  id: number;
  title?: string;
  name?: string;
  content?: {
    text: string;
  }[];
};

type UpdateParamsType = Content[];

export const getHomePageContent = async () => {
  const response = await request.get(`content/home`);
  return response;
};

export const getJoinPageContent = async () => {
  const response = await request.get(`content/join`);
  return response;
};

export const updateJoinPageContent = async (params: UpdateParamsType) => {
  const response = await request.put(`content/join`, params);

  return response;
};

export const updateHomePageContent = async (params: UpdateParamsType) => {
  const response = await request.put(`content/home`, params);
  return response;
};

export const getContactPageContent = async () => {
  const response = await request.get(`content/contact`);
  return response;
};

export const updateSearchPageContent = async (params: {
  name: string;
  iconUrl: string;
}) => {
  const { name, iconUrl } = params;

  const response = await request.put(`content/search-page`, { name, iconUrl });

  return response;
};

export const uploadSearchPageMapUserIcon = async (params: { file: File }) => {
  const { file } = params;

  const formData = new FormData();
  formData.append("file", file);

  const response = await request.post(
    `content/search-page`,
    formData,
    {
      "Content-Type": "multipart/form-data",
    },
    true,
  );

  return response;
};
