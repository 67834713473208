// src/components/ContentPreview.tsx
import React from "react";
import { Card } from "antd";
import styles from "../newBlogPost.module.css";
import parse from "html-react-parser";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

type Props = {
  post: any;
  coverImage: any;
};

const ContentPreview = (props: Props) => {
  const { post, coverImage } = props;
  return (
    <div className={styles.previewWrapper}>
      <Card>
        <h2>Preview</h2>
        <div className={styles.preview}>
          {coverImage ? (
            <div className={styles.coverWrapper}>
              <img className={styles.coverImage} src={coverImage} alt="cover" />
              <p className={styles.coverTitle}>{post?.title}</p>
            </div>
          ) : (
            <h1>{post?.title}</h1>
          )}
          {post.content.map((content: any, index: number) => {
            if (content) {
              if (content.getCurrentContent) {
                const htmlContent = draftToHtml(
                  convertToRaw(
                    content.getCurrentContent
                      ? content.getCurrentContent()
                      : null,
                  ),
                );

                return parse(htmlContent);
              } else {
                return parse(content);
              }
            }
            return null;
          })}
        </div>
      </Card>
    </div>
  );
};

export default ContentPreview;
