import React, { useEffect } from "react";
import {
  deletePotentialCustomer,
  getPotentialCustomers,
  uploadPotentialCustomersCSV,
} from "../../services/potential-customers/potential-customers.service";
import { Button, Col, Input, message, Modal, Row, Table, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import styles from "./potential-customers.module.css";

const PotentialCustomers = () => {
  const [potentialCustomers, setPotentialCustomers] = React.useState<any[]>([]);
  const [originalData, setOriginalData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const handleGetPotentialCustomers = async () => {
    setLoading(true);
    const result = await getPotentialCustomers();
    setLoading(false);
    if (result?.data) {
      setPotentialCustomers(result.data);
      setOriginalData(result.data);
    }
  };

  const handleUploadPotentialCustomersCSV = async (file: any) => {
    setLoading(true);
    const result = await uploadPotentialCustomersCSV(file);
    if (result?.data) {
      await handleGetPotentialCustomers();
    }
    setLoading(false);
  };

  const handleDeletePotentialCustomer = async (id: number) => {
    setLoading(true);
    const result = await deletePotentialCustomer(id);
    if (result.data) {
      await handleGetPotentialCustomers();
    } else {
      message.error("Failed to delete potential customer");
    }
    setLoading(false);
  };

  const handleSearch = (value: string) => {
    if (value) {
      const filtered = originalData.filter((customer) =>
        customer.email.includes(value),
      );
      setPotentialCustomers(filtered);
    } else {
      setPotentialCustomers(originalData);
    }
  };

  useEffect(() => {
    handleGetPotentialCustomers();
  }, []);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Input.Search
                placeholder="Search by email"
                className={styles.searchInput}
                onSearch={handleSearch}
              />
            </div>
            <div>
              <Upload
                multiple={false}
                showUploadList={false}
                accept=".csv"
                customRequest={async (options) => {
                  await handleUploadPotentialCustomersCSV(options.file);
                }}
              >
                <Button
                  loading={loading}
                  icon={<UploadOutlined />}
                  type="primary"
                >
                  Upload CSV
                </Button>
              </Upload>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Table
            dataSource={potentialCustomers}
            loading={loading}
            rowKey={(record) => record.id}
            columns={[
              {
                title: "ID",
                dataIndex: "id",
              },
              {
                title: "Company Name",
                dataIndex: "companyName",
              },
              {
                title: "Name",
                dataIndex: "name",
              },
              {
                title: "Address",
                dataIndex: "address",
              },
              {
                title: "Email",
                dataIndex: "email",
              },
              {
                title: "Created At",
                dataIndex: "createdAt",
                render: (text: any) => {
                  return new Date(text).toLocaleDateString();
                },
              },
              {
                title: "Action",
                render: (record) => (
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: "Are you sure?",
                        content: "This action cannot be undone",
                        onOk: async () => {
                          await handleDeletePotentialCustomer(record.id);
                        },
                      });
                    }}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};
export default PotentialCustomers;
