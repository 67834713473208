import React, { useCallback, useEffect } from "react";
import { getEmailSendingStatus } from "../../services/email/email.service";
import { Button, Card, Col, Row } from "antd";
import EmailStatusCard from "../../components/EmailStatusCard";
import { useNavigate } from "react-router";

type EmailStatus = {
  allUsers: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  newsletterSubscribers: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  potentialCustomers: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  providedEmails: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  selectedNewsletterSubscribers: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
  selectedPotentialCustomers: {
    total: number;
    sent: number;
    failed: number;
    inProgress: boolean;
  };
};

const EmailDashboard = () => {
  const [emailStatus, setEmailStatus] = React.useState<EmailStatus | null>(
    null,
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleGetEmailStatus = useCallback(async () => {
    setLoading(true);
    const result = await getEmailSendingStatus();
    setEmailStatus(result);
    setLoading(false);
  }, []);

  useEffect(() => {
    handleGetEmailStatus();
  }, [handleGetEmailStatus]);

  return (
    <Card>
      <Row align="middle">
        <Col span={20}>
          <h1>Email Dashboard</h1>
        </Col>
        <Col span={4}>
          <Button
            block
            type="primary"
            onClick={() => {
              navigate("/email/send");
            }}
          >
            Send New Email
          </Button>
        </Col>
      </Row>
      <p>Here you can see the status of emails being sent</p>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <EmailStatusCard
            title="All Users"
            status={emailStatus?.allUsers}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <EmailStatusCard
            title="Newsletter Subscribers"
            status={emailStatus?.newsletterSubscribers}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <EmailStatusCard
            title="Potential Customers"
            status={emailStatus?.potentialCustomers}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <EmailStatusCard
            title="Selected Newsletter Subscribers"
            status={emailStatus?.selectedNewsletterSubscribers}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <EmailStatusCard
            title="Selected Potential Customers"
            status={emailStatus?.selectedPotentialCustomers}
            loading={loading}
          />
        </Col>
        <Col span={12}>
          <EmailStatusCard
            title="Provided Emails"
            status={emailStatus?.providedEmails}
            loading={loading}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default EmailDashboard;
