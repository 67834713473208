import { request } from "../request";
import { message } from "antd";

export const getAllUsers = async () => {
  const response = await request.get(`user`);
  if (response?.code) {
    message.error("Kullanıcı verileri alınamadı");
    return [];
  }
  return response;
};

export const getUserById = async (id: string) => {
  const response = await request.get(`users/id/${id}`);
  return response;
};
