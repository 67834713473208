import React from "react";
import { Button, Card, Modal, Table } from "antd";
import { useContact } from "./contact.hook";

const Contact = () => {
  const { messages, loading, deleteMessage } = useContact();

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (record: any) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <Button
          danger
          onClick={() =>
            Modal.confirm({
              title: "Delete message",
              content: "Are you sure you want to delete this message?",
              onOk: () => deleteMessage(record.id),
              okType: "danger",
              okText: "Delete",
            })
          }
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <Card>
      <h2>Contact Messages</h2>
      <Table
        columns={columns}
        dataSource={messages}
        loading={loading}
        rowKey={(record: any) => {
          if (record && record.id) {
            return record.id;
          } else {
            return "";
          }
        }}
      />
    </Card>
  );
};

export default Contact;
