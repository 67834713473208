import React, { useCallback, useEffect } from "react";
import {
  getCategories,
  updateCategory,
} from "../../services/category/category.service";
import { uploadProjectImage } from "../../services/firm/firm.service";
import { Button, Col, Image, message, Row, Spin, Upload } from "antd";

const CategoryEdit = () => {
  const [categories, setCategories] = React.useState<
    {
      id: number;
      name: string;
      title: string;
      image: string;
    }[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  const handleUploadCategoryImage = async (id: number, file: any) => {
    setLoading(true);
    const result = await uploadProjectImage({
      file,
    });

    if (result?.url) {
      const catResult = await handleUpdateCategory(id, result.url);

      if (catResult) {
        const category = categories.find((category) => category.id === id);
        if (category) {
          const updatedCategory = {
            ...category,
            image: result.url,
          };
          const updatedCategories = categories.map((category) =>
            category.id === id ? updatedCategory : category,
          );
          setCategories(updatedCategories);
        }
      } else {
        message.error("Failed to upload category image.");
      }
    }
    setLoading(false);
  };

  const handleUpdateCategory = async (id: number, params: any) => {
    const response = await updateCategory(id, params);
    return response;
  };

  const handleGetCategories = useCallback(async () => {
    const result = await getCategories();
    if (result?.data) {
      setCategories(result.data);
    }
  }, []);

  useEffect(() => {
    handleGetCategories();
  }, [handleGetCategories]);
  return (
    <div>
      <Spin spinning={loading} fullscreen size="large" />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Categories
          </h3>
        </Col>
        {categories?.map((category) => (
          <Col span={6}>
            <div
              key={category.id}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>{category.title}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                  src={category.image}
                  alt={category.name}
                  width={"100%"}
                  height={200}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 16,
              }}
            >
              <Upload
                multiple={false}
                showUploadList={false}
                accept="image/*"
                customRequest={async (options) => {
                  await handleUploadCategoryImage(category.id, options.file);
                }}
              >
                <Button type="primary">Upload Image</Button>
              </Upload>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryEdit;
