import { request } from "../request";

export const getContactMessages = async () => {
  const response = await request.get(`contact-us`);
  return response;
};

export const deleteContactMessage = async (messageId: string) => {
  const response = await request.delete(`contact-us`, { id: messageId });
  return response;
};
