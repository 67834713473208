import React, { useCallback, useEffect, useState } from "react";
import styles from "./newBlogPost.module.css";
import { Button, Col, message, Row, Spin } from "antd";
import "react-quill/dist/quill.snow.css";
import { request } from "../../services/request";
import { useNavigate, useParams } from "react-router";
import ContentForm from "./components/ContentForm";
import ContentPreview from "./components/ContentPreview";

type Post = {
  id?: number;
  popular?: boolean;
  slug?: string;
  coverImage?: string;
  author?: string;
  title: string;
  metaTitle: string;
  metaDescription: string;
  content: any[];
};

const NewBlogPost = () => {
  const [post, setPost] = useState<Post>({
    title: "",
    metaTitle: "",
    metaDescription: "",
    content: [],
  });
  const { slug } = useParams();
  const navigate = useNavigate();
  const [postImages, setPostImages] = useState<string[]>([]);
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const handleUploadProjectImage = async (image: any, index: number) => {
    const formData = new FormData();
    formData.append("file", image, image.name);
    setLoading(true);
    const result = await request.post(
      "firm/admin/project-image",
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      true,
    );

    setLoading(false);
    if (result?.url) {
      setPostImages([...postImages, result.url]);
      const updatedContent = post.content;
      updatedContent[index] =
        `<img src="${result.url}" alt="" style="width: 100%; object-fit: cover; object-position: center" />`;
      setPost({ ...post, content: updatedContent });

      message.success("Post image uploaded successfully.");
    } else {
      message.error("Failed to upload project image.");
    }
  };

  const fetchPostDetails = useCallback(async () => {
    setLoading(true);
    const result = await request.get(`posts/${slug}`);
    if (!result.data) {
      message.error("Post not found");
      navigate("/");
      return;
    }
    setLoading(false);
    if (result) {
      setPost(result.data);
      setCoverImage(result.data.coverImage);
    } else {
      message.error("Post not found");
      navigate("/");
    }
  }, [slug, navigate]);

  useEffect(() => {
    if (slug) {
      // @ts-ignore
      fetchPostDetails();
    }
  }, [slug, fetchPostDetails]);

  const handleUploadCoverImage = async (image: any) => {
    const formData = new FormData();
    formData.append("file", image, image.name);
    setLoading(true);
    const result = await request.post(
      "firm/admin/project-image",
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
      true,
    );

    setLoading(false);
    if (result?.url) {
      setCoverImage(result.url);
      message.success("Cover image uploaded successfully.");
    } else {
      message.error("Failed to upload cover image.");
    }
  };

  const handleSubmit = async () => {
    const doesExist = !!slug;
    const id = post.id;

    const htmlContent = post.content.map((item) => {
      if (item.image) {
        return `<img src="${item.image}" alt="${item.title}" />`;
      }
      return item;
    });

    if (doesExist) {
      setLoading(true);
      const result = await request.put(`posts/${id}`, {
        title: post?.title,
        metaTitle: post?.metaTitle,
        metaDescription: post?.metaDescription,
        content: htmlContent,
        coverImage: coverImage,
        slug: post?.slug,
      });
      setLoading(false);
      if (result) {
        message.success("Post updated successfully.");
        navigate("/blog");
      } else {
        message.error("Failed to update post.");
      }
      return;
    }
    setLoading(true);
    const result = await request.post("posts", {
      title: post?.title,
      metaTitle: post?.metaTitle,
      metaDescription: post?.metaDescription,
      content: htmlContent,
      coverImage: coverImage,
      slug: post?.slug,
    });
    setLoading(false);
    if (result) {
      message.success("Post created successfully.");
      navigate("/blog");
    } else {
      message.error("Failed to create post.");
    }
  };

  return (
    <div className={styles.container}>
      <Spin spinning={loading} fullscreen />
      <h1>Create Blog Post</h1>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <ContentForm
            post={post}
            setPost={setPost}
            handleUploadProjectImage={handleUploadProjectImage}
            handleUploadCoverImage={handleUploadCoverImage}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <ContentPreview post={post} coverImage={coverImage} />
        </Col>
      </Row>
      <Row justify="start">
        <Col span={24}>
          <Button onClick={handleSubmit} type="primary" block>
            Publish Post
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewBlogPost;
