import React, { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import { Card } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type Props = {
  content: { text: string } | string;
  index: number | null;
  setContent: (content: string) => void;
};

const CustomEditor = (props: Props) => {
  const { setContent } = props;
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(
    () => {
      if (typeof props.content === "string") {
        return EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(props.content).contentBlocks,
          ),
        );
      }
      if (typeof props.content === "object") {
        return props.content.text
          ? EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(props.content.text).contentBlocks,
              ),
            )
          : EditorState.createEmpty();
      }
      return EditorState.createEmpty();
    },
  );

  useEffect(() => {
    if (editorState) {
      setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  }, [editorState, setContent]);

  return (
    <Card>
      <Editor
        editorState={editorState}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "emoji",
            "remove",
            "history",
          ],
        }}
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </Card>
  );
};

export default CustomEditor;
